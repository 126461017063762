<template>
    <div>
        <common-header></common-header>

        <div class="subtop">
            <div class="subtopin">
                <img :src="require('@/assets/img/sub/sub_top_img.png')"/>
                <div class="subtit">PARTNERS</div>
            </div>
        </div>

        <div class="sbreadcrumb">
            <div class="bdinner">
                <span><img :src="require('@/assets/img/sub/home_icon.svg')"></span>
                <span><img :src="require('@/assets/img/sub/next_arrow.svg')"></span>
                <span class="locatit">Partners</span>
            </div>

        </div>
        <div class="scontainer">
            <div class="section">
                <div class="sectionin">
                    <div class="stit">파트너스</div>
                    <p class="stxt02">
                        우리의 비즈니스 전략은 파트너 네트워크를 유지하고 커스터마이징 프로그램을 지원함으로서 제품, 서비스 자체 성장과
                        고객 기반 확대에 초점을 맞추고 있습니다.
                    </p>
                    <p class="stxt03">
                        TINTEC C&C 비즈니스 파트너 프로그램 방식으로 고객 요구를
                        충족하는 입증 된 솔루션을 제공 하여 회사의 성공과 성장을 지원하도록 설계되었습니다.
                    </p>
                    <p class="stxt03">
                        다양한 리소스, 고육 및 도구를 제공하여 전문성을 개발하고
                        차별화 하며 비즈니스를 성장시키고 수익성을 높일 수 있습니다.
                    </p>

                </div>
                <div class="section_partners">
                    <div class="partners">
                        <div class="partners_wrap">
                            <span>마이크로소프트 실버 파트너스</span>
                            <span>LG CNS 파트너</span>
                            <span  class="font-weight-bold">티엔코아</span>
                            <span>닷넷 소프트</span>
                            <span>디플럭스 씨엔씨</span>
                            <span>T5Online</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       <!-- <section>
            <common-site-map></common-site-map>
        </section>-->
        <common-footer></common-footer>
    </div>
</template>

<style scoped>
    /*_-------------------------------------------------------------------서브페이지 공통*/

    #header {
        width: 100%;
        margin: 0 auto;
        height: 100px;

    }

    .m_header {
        display: none;
    }

    .header_container {
        position: absolute;
        height: 100px;
        width: 100%;
        left: 0;
        z-index: 1000;
    }

    .open {
        display: block;
        /*background: #f9f9f9;*/
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ start*/
    .header_container {
        position: relative;
        /*background: #f9f9f9;*/
        height: 300px;
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ end*/
    .header_container::before {
        content: "";
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
    }

    .header_container.open::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
        background-color: #fff;
    }


    .header_container .inner {
        position: relative;
        width: 100%;
        max-width: 1200px;
        height: 100px;
        margin: 0 auto;
    }


    .header_container .logo {
        position: absolute;
        top: 28px;
        z-index: 111;
    }


    .header_menu_wrap {
        position: absolute;
        height: 100px;
        z-index: 30;
        width: 100%;
        left: 0px;
        top: 37px;
    }

    .header_menu_wrap > ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
        width: 1200px;
        margin: 0 auto;
        position: relative;
        padding-left: 281px;
    }

    .header_menu_wrap > ul > li {
        width: 170px;
        display: inline-block;
        text-align: right;
        position: relative;
    }

    .header_menu_wrap > ul > li > a:hover {
        color: #0f70b7;
    }

    .sub_menu > ul > li:hover {
        text-decoration: underline;
        cursor: pointer;
    }


    .header_menu_wrap > ul > li > a {

        font-size: 1.8rem;
        letter-spacing: -0.54px;
        line-height: 27px;
        font-weight: 700;
        color: #000;
        font-family: 'Noto Sans KR', sans-serif;
    }

    .header_menu_wrap > ul > li > a:hover {
        text-decoration: none;
    }


    .sub_menu {
        /*position: absolute;
        top: 53px;
        z-index: 99;
        padding: 0;
        background-color: red;
        width: 100%;*/
        position: absolute;
        top: 63px;
        left: 50px;
        z-index: 30;
        padding-bottom: 36px;
        display: none;
    }

    .menu01 {
        left: 109px;
    }

    .menu02 {
        left: 71px;
    }

    .menu03 {
        left: 75px;
    }

    .menu04 {
        left: 86px;
    }

    .menu05 {
        left: 68px;
    }


    .sub_menu > ul {
        /* list-style: none;
         padding: 0;
         max-width: 1200px;
         margin: 0 auto;*/
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;


    }

    .sub_menu > ul > li {
        /*width: calc(25% - 36px);*/
        width: 170px;

        padding-top: 26px;
        font-size: 1.4rem;
        line-height: 20px;
        letter-spacing: 0.35px;
        color: #333;
        text-align: left;
    }


    /*pc header end*/


    .subtop {
        width: 100%;
        height: 296px;
        border-top: 1px solid #ddd;
    }

    .subtop .subtopin {
        width: 1200px;
        margin: 0 auto;


    }

    .subtop .subtopin img {
        width: 418px;
        display: inline-block;
        margin-left: 10%;
    }

    .subtop .subtopin .subtit {
        display: inline-block;
        font-size: 4.5rem;
        letter-spacing: -2.25px;
        font-weight: 900;
        color: #000000;
        vertical-align: middle;
    }

    .sbreadcrumb {
        width: 100%;
        background: #0F70B7;
        height: 62px;
    }

    .sbreadcrumb .bdinner {
        width: 1200px;
        margin: 0 auto;
        padding-left: 20px;
    }

    .sbreadcrumb .bdinner span {
        padding-right: 20px;
        vertical-align: bottom;
        display: inline-block;
        padding-top: 19px;
    }

    .sbreadcrumb .bdinner span img {
        vertical-align: sub;
    }

    .sbreadcrumb .bdinner span.locatit {
        font-size: 1.8rem;
        color: #fff;
        font-weight: 900;
        padding-top: 13px;
        display: inline-block;
    }

    .scontainer {
        width: 100%;
    }

    .scontainer .section {
        /*padding: 100px 20px;*/
        padding: 48px 0 0 0;
    }

    .scontainer .section .sectionin {
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
    }

    .scontainer .section .sectionin .stit {
        color: #000;
        font-size: 4.5rem;
        letter-spacing: -1.15px;
        line-height: 70px;
        font-weight: 900;
        padding-top: 20px;
        text-align: center;
    }

    .scontainer .section .sectionin .stxt {
        font-size: 1.8rem;
        color: #000;
        line-height: 18px;
        letter-spacing: -0.9px;
        text-align: center;
        padding-top: 12px;
        line-height: 27px;
    }

    /*_-------------------------------------------------------------------서브페이지 공통*/
    .scontainer .section .sectionin .stxtb {
        font-size: 2.3rem;
        letter-spacing: -1.15px;
        text-align: center;
        color: #000;
        margin-top: 14px;
        font-weight: 600;
    }

    .fblue {
        color: #2d84e2
    }

    .scontainer .section .sectionin .stxt02 {
        font-size: 1.9rem;
        color: #000;
        line-height: 18px;
        letter-spacing: -1px;
        text-align: center;
        padding-top: 50px;
        line-height: 35px;
        margin: 0;
    }

    .scontainer .section .sectionin .stxt03 {
        font-size: 1.9rem;
        color: #000;
        line-height: 18px;
        letter-spacing: -1px;
        text-align: center;
        padding: 0;
        line-height: 35px;
        margin: 0;
    }

    .partners {
        width: 100%;
        height: 130px;
        background-color: #FAFAFA;
        padding: 0;
    }

    .partners_wrap {
        line-height: 130px;
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 0 auto;
    }

    .partners_wrap span {
        font-size: 2.0rem;
    }

    .partners_wrap span:nth-child(3) {
        font-size: 3.0rem;
        color: #000;
    }

    .section_partners {
        margin-top: 80px;
    }

    .five_content {
        width: 100%;
        height: 202px;
        background-color: #ececec;
        border-bottom: 1px solid #cccccc;
    }

    .five_content > ul {
        padding: 40px 0 0 0;
        font-weight: bold;
        color: #666;
        font-size: 1.4rem;
        width: 1200px;
        margin: 0 auto;
        list-style: none;
        display: flex;
        justify-content: space-between;
    }

    .five_content > ul > li > ul {
        width: 240px;
        list-style: none;
        padding: 16px 0 0 0;
        font-weight: normal;
        color: #9a9a9a;
    }

    .five_content > ul > li > ul > li {
        padding-bottom: 12px;
        cursor: pointer;
    }

    .footer {
        width: 100%;
        height: 100px;
        background-color: #ececec;
    }

    .footer_wrap {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: start;
        font-size: 1.3rem;
        color: #9a9a9a;
        padding: 23px 20px 0px 0px;
    }

    .footer_wrap > div {
        margin-top: -13px;
    }

    .footer_flex {
        line-height: 18px;
        width: 100%;
        padding-left: 180px;
    }

    .footer_flex span {
        display: block;
        margin-top: 4px;
        font-size: 1.3rem;
        color: #9a9a9a;
    }

    .familyarea {
        margin-left: 72px;
    }

    .familyarea select {
        margin-left: 20px;
    }

    select {
        background: transparent;
        border: 1px solid #cccccc !important;
        width: 170px !important;
        height: 30px;
        color: #9a9a9a;
    }


    /*---------------------------------------------------------------------------------------------------tablet*/
    @media screen and (max-width: 1199px) and (min-width: 750px) {
        .scontainer .section .sectionin {
            padding: 0 40px;
            width: 100%;
        }

        /*모바일 헤더 메뉴 start */
        #header {
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo {
            width: 150px;
            height: 25px;
        }

        .logo > a > img {
            width: 100%;
        }

        .m_header div:nth-child(2) {
            width: 30px;
            height: 30px;
        }

        .m_header div:nth-child(2) img {
            width: 100%;
        }

        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            display: none;
        }

        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
            font-size: 1.6rem;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 50%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .subtop .subtopin img {
            width: 418px;
            display: inline-block;
            margin-left: 6%;
        }

        .li_background {
            background-color: #EDF2F6;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 45% !important;
            top: 4px !important;
        }

        .m_close_button > img {
            width: 24px !important;
            height: 24px !important;
        }

        .header_container {
            display: none;
            width: 100%;

        }

        .header_menu_wrap {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 340px;
            top: -7px;
        }

        /*모바일 헤더 메뉴 end */
        .partners {
            width: 100%;
            height: 130px;
            background-color: #FAFAFA;
            padding: 0;
        }

        .partners_wrap {
            width: 100% !important;
            line-height: 130px;
            display: flex;
            justify-content: space-around;
        }

        .partners_wrap span:first-child,
        .partners_wrap span:nth-child(7) {
            display: none;
        }

        .partners_wrap span {
            font-size: 2.0rem;
            color: #777;
        }

        .partners_wrap span:nth-child(4) {
            font-size: 2.0rem;
        }

        .five_content {
            width: 100%;
            padding: 0 40px;
        }

        .five_content > ul {
            width: 100%;
            margin: 0 auto;
            font-size: 1.3rem;
            letter-spacing: -0.5px;
        }

        .five_content > ul > li > ul {
            width: 100%;
        }

        .footer {
            width: 100%;
            height: 130px;
            background-color: #ececec;
            padding-bottom: 20px;
        }

        .footer .footer_wrap {
            width: 100%;
            padding: 0 40px;
        }

        .footer_wrap > div {
            margin-top: 14px;
        }

        .footer_flex {
            line-height: 18px;
            width: 100%;
            padding-left: 50px;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
        }

        .footer_flex span {
            display: block;
            margin-top: 4px;
            font-size: 1.3rem;
            color: #9a9a9a;
        }

        .familyarea {
            margin-left: 0px;
            margin-top: 10px;
        }

        .familyarea select {
            margin-left: 0px;
        }


    }


    /*---------------------------------------------------------------------------------------------------mobile*/
    @media (max-width: 749px) {

        /*모바일 헤더 메뉴 start */
        #header {
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo {
            width: 105px;
            height: 25px;
        }

        .logo > a > img {
            width: 100%;
        }

        .subtop {
            border-top: none;
        }

        /*모바일 헤더 메뉴 start */
        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            display: none;
        }

        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 70%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
            overflow-y: auto;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .li_background {
            background-color: #EDF2F6;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 24%;
            top: -7px;
        }

        /*모바일 헤더 메뉴 end */
        .subtop {
            width: 100%;
            height: 96px;
            border-bottom: 1px solid #0F70B7;
        }

        .subtop .subtopin {
            width: 100%;
        }

        .subtop .subtopin img {
            width: 135px;
            display: inline-block;
            margin-left: 10%;
        }

        .subtop .subtopin .subtit {
            display: inline-block;
            font-size: 2rem;
            letter-spacing: -1px;
            font-weight: 900;
            color: #000000;
            vertical-align: middle;
        }

        .sbreadcrumb {
            display: none;
        }

        .scontainer .section {
            padding: 48px 0 0 0;
        }

        .scontainer .section .sectionin {
            padding: 0 20px;
            width: 100%;
        }

        .scontainer .section .sectionin .stit {
            color: #000;
            font-size: 2rem;
            letter-spacing: -1px;
            line-height: 30px;
            font-weight: 900;
            padding-top: 20px;
            text-align: center;
        }

        .scontainer .section .sectionin .stxt {
            font-size: 1.4rem;
            color: #000;
            line-height: 24px;
            letter-spacing: -0.7px;
            text-align: left;
            padding-top: 12px;
            line-height: 24px;
        }

        .scontainer .section .sectionin .stxtb {
            font-size: 1.6rem;
            letter-spacing: -1.15px;
            text-align: center;
            color: #000;
            margin-top: 14px;
            font-weight: 600;
        }

        .scontainer .section .sectionin .stxt02 {
            font-size: 1.4rem;
            color: #000;
            line-height: 18px;
            letter-spacing: -1px;
            text-align: center;
            padding-top: 12px;
            line-height: 24px;
            text-align: left;
        }

        .scontainer .section .sectionin .stxt03 {
            font-size: 1.4rem;
            color: #000;
            line-height: 18px;
            letter-spacing: -1px;
            text-align: center;
            line-height: 24px;
            text-align: left;
            margin-top: 8px;
        }

        .section_partners {
            margin-top: 52px;
        }

        .partners {
            width: 100%;
            height: 130px;
            background-color: #FAFAFA;
            padding: 0;
        }

        .partners_wrap {
            width: 100% !important;
            line-height: 130px;
            display: flex;
            justify-content: space-around;
            font-size: 1.6rem;
        }

        .partners_wrap span {
            font-size: 1.6rem;
        }

        .partners_wrap span:nth-child(3) {
            font-size: 2.2rem;
            color: #000;
        }

        .partners_wrap > span:nth-child(1),
        .partners_wrap > span:nth-child(4),
        .partners_wrap > span:nth-child(6),
        .partners_wrap > span:nth-child(7) {
            display: none;
        }

        .five_content {
            display: none;
        }

        .footer {
            width: 100%;
            height: 197px;
            padding: 0 20px;
        }

        .footer_wrap {
            flex-direction: column;
            width: 100%;
            padding-top: 0;
        }

        .footer_wrap > div {
            text-align: center;
            margin: 0 auto;
        }

        .footer_wrap > div > img {
            width: 140px;
            height: 55px;
        }

        .footer_flex {
            padding-left: 0;
            flex-direction: column-reverse;
        }

        .foottxt {
            width: 100%;
            margin: 0 auto;
            padding-top: 25px;
        }

        .familyarea {
            margin-left: 0;
            padding-top: 10px;
        }

    }

</style>

<script>
    import CommonHeader from "@/components/CommonHeader";
    import CommonFooter from "@/components/CommonFooter";

    export default {
        name: "Partners",
        components: {CommonFooter, CommonHeader}
    }
</script>